<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 100px;">
                                出库单编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.outNum" size="small" placeholder="请输入出库编号"></el-input>
                            </td>
                            <td style="width: 80px">
                                出库仓库：
                            </td>
                            <td>
                                <el-select v-model="searchItem.warehouseType" style="width: 100%" size="small"
                                           placeholder="请选择出库仓库">
                                    <el-option
                                            v-for="item in depotList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                出库时间
                            </td>
                            <td width="26%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46.5%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date" placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small" @click="addObject"
                                           v-if="hasAuthority('out_warehouse_add')">
                                    增加
                                </el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel(1)">导出出库总表</el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel(2)">导出售后出库表</el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outNum" width="170" label="出库单编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="listNum" width="170" label="装箱清单编号" align="center"
                            show-overflow-tooltip></el-table-column>
                            <el-table-column prop="afterSalesNum" width="170" label="售后申请单编号" align="center"
                            show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outType" width="160" label="出库类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.outType == 0">销售出库</span>
                                    <span v-else-if="scope.row.outType ==1">研发物料出库</span>
                                    <span v-else-if="scope.row.outType ==2">外协出库</span>
                                    <span v-else-if="scope.row.outType ==3">售后出库</span>
                                    <span v-else-if="scope.row.outType ==4">生产领料</span>
                                    <span v-else-if="scope.row.outType ==5">售后维修出库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="outTime" width="160" label="出库时间" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span>{{changeDateFormat(scope.row.outTime)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="warehouseType" width="160" label="出库仓库" align="center"
                                             show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="createUserName" width="80" label="操作人" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.updateUserName">{{scope.row.updateUserName}}</span>
                                    <span v-else>{{scope.row.createUserName}}</span>

                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" width="200" label="备注" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="isOuting" label="状态" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.isOuting == 0" style="color: red">未出库</span>
                                    <span v-else-if="scope.row.isOuting ==1" style="color: green;">已出库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" style="margin-right: 10%" title="编辑"
                                           v-if="hasAuthority('out_warehouse_update') && scope.row.isOuting == 0"
                                           @click="editOutDepot(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showOutDepot(scope.row.id)"></i>
                                        <i class="iconfont icontongguo" style="margin-left: 10%" title="出库"
                                           v-if="hasAuthority('out_warehouse_sure') && scope.row.isOuting == 0"
                                           @click="submitOutDepot(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    width="55%"
                    :close-on-click-modal="false"
                    center>
                <div class="basic-info">
                    <el-form :model="outDepotForm" :rules="outDepotRules" ref="outDepotForm" label-width="100px"
                             class="demo-ruleForm">
                        <el-row>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item size="small" label="出库类型" prop="outType">
                                    <el-select
                                            size="small"
                                            style="width: 100%"
                                            v-model="outDepotForm.outType"
                                            placeholder="请选择出库类型"
                                    >
                                        <el-option
                                                v-for="item in depotTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item size="small" label="出库仓库" prop="warehouseType">
                                    <el-select
                                            size="small"
                                            multiple 
                                            style="width: 100%"
                                            v-model="outDepotForm.warehouseType"
                                            placeholder="请选择采购仓库"
                                    >
                                        <el-option
                                                v-for="item in depotList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item size="small" label="备注" prop="remark">
                            <el-input v-model="outDepotForm.remark" type="textarea" size="small"
                                      placeholder="备注"></el-input>
                        </el-form-item>
                        <div class="edit-select">
                            <table width="70%">
                                <tr>
                                    <!-- <td width="80px">
                                        物料编码：
                                    </td>
                                    <td>
                                        <el-input v-model="editSearchItem.materialCode" size="mini"
                                                  placeholder="请输入物料编码"></el-input>
                                    </td>
                                    <td width="50px">
                                        部件：
                                    </td> -->
                                    <td style="text-align:left">
                                        <!-- <el-select v-model="editSearchItem.component" size="mini" style="width: 100%"
                                                   placeholder="请选择部件">
                                            <el-option
                                                    v-for="item in parts"
                                                    :key="item.component"
                                                    :label="item.componentName"
                                                    :value="item.component">
                                            </el-option>
                                        </el-select> -->
                                        <template v-for="(item,i) in outDepotForm.warehouseType" >
                                            <el-button v-if="item==0" :key="i" type="primary" size="mini" icon="el-icon-plus" @click="addAfterMaterial(0)">新增主仓库物料</el-button>
                                            <el-button v-if="item==1" :key="i" type="primary" size="mini" icon="el-icon-plus" @click="addAfterMaterial(1)">新增研发仓库物料</el-button>
                                            <el-button v-if="item==2" :key="i" type="primary" size="mini" icon="el-icon-plus" @click="addAfterMaterial(2)">新增外协仓库物料</el-button>
                                            <el-button v-if="item==3" :key="i" type="primary" size="mini" icon="el-icon-plus" @click="addAfterMaterial(3)">新增售后仓库物料</el-button>
                                        </template>
                                        <!-- <el-button type="primary" size="mini" icon="el-icon-plus" @click="addMainMaterial">新增物料信息</el-button> -->
                                    </td>
                                    <td width="180px" style="text-align:left">
                                        <!-- <el-button type="primary" icon="el-icon-search" size="mini"
                                                   @click="editSearchBtn">
                                            搜索
                                        </el-button> -->
                                        <!-- <el-button type="primary" icon="el-icon-refresh" size="mini" @click="editReset">
                                            重置
                                        </el-button> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <el-table
                                :data="new_tableList"
                                border
                                ref="multipleTable"
                                style="width: 100%;margin-bottom: 10px"
                                max-height="340"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                        >
                            <!-- <el-table-column type="selection" width="40" align="center"></el-table-column> -->
                            <el-table-column prop="materialCode" label="物料编码" width="80" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip>
                                            </el-table-column>
                            <el-table-column prop="warehouseTypeStr" label="仓库类型" align="center"
                                             show-overflow-tooltip>
                                             <template slot-scope="scope">
                                                 <span v-if="scope.row.warehouseTypeStr==0">主物料仓库</span>
                                                 <span v-if="scope.row.warehouseTypeStr==1">研发物料仓库</span>
                                                 <span v-if="scope.row.warehouseTypeStr==2">外协仓库</span>
                                                 <span v-if="scope.row.warehouseTypeStr==3">售后仓库</span>
                                            </template>
                                             </el-table-column>
                            <el-table-column prop="quantity" width="90" label="库房库存" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalQuantity" width="90" label="总库存" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outCount" width="120" label="数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input type="tel" size="small"
                                              v-model.number="scope.row.outCount" 
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              placeholder="请输入数量" ></el-input>
                                    <!-- <el-input type="tel" size="small"
                                              v-model.number="scope.row.outCount" v-else
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              placeholder="请输入数量"></el-input> -->
                                </template>
                            </el-table-column>
                            <el-table-column  label="操作" align="center" width="70">
                                <template slot-scope="scope">
                                        <i class="el-icon-delete" style="color:red;font-size:18px;cursor:pointer" @click="deleteNew_tableList(scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                        <el-button @click="cancel('outDepotForm')">取 消</el-button>
                        <el-button type="primary" @click="submit('outDepotForm')" :loading="isLoad"> 确 定</el-button>
                    </span>
            </el-dialog>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="50%"
                    :close-on-click-modal="false"
                    center>
                <div class="infomation">
                    <table width="100%">
                        <tr>
                            <td width="100px" style="text-align: left">出库单编号:</td>
                            <td style="text-align: left">{{outDisplay.outNum}}</td>
                            <td width="100px" style="text-align: left">出库类型:</td>
                            <td style="text-align: left">
                                {{outDisplay.outType == 0?"销售出库":
                                (outDisplay.outType == 1?"研发物料出库" :
                                outDisplay.outType == 2?"外协入库":
                                outDisplay.outType == 3?'售后入库':
                                outDisplay.outType == 4?'生产领料': '售后维修出库')}}
                            </td>
                            <td width="100px">出库仓库:</td>
                            <td style="text-align: left">{{outDisplay.warehouseType}}
                            </td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">出库时间:</td>
                            <td style="text-align: left;padding-top: 10px">{{changeDateFormat(outDisplay.outTime)}}</td>
                            <td width="100px" style="text-align: left;padding-top: 10px">出库时间:</td>
                            <td style="text-align: left;padding-top: 10px">{{outDisplay.isOuting == 0?'未出库':'已出库'}}</td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">备注:</td>
                            <td style="text-align: left;padding-top: 10px" colspan="5">{{outDisplay.remark}}</td>
                        </tr>
                        <tr>
                            <td colspan="6" style="padding-top: 10px">
                                <el-table
                                        :data="outDisplay.list"
                                        border
                                        style="width: 100%;margin-bottom: 10px"
                                        max-height="340"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: lineHeight}">
                                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="materialName" label="产品名称" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="model" label="型号" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="warehouseType" label="出库仓库" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <!--                                    <el-table-column prop="tempQuantity" label="库存数量" align="center"-->
                                    <!--                                                     show-overflow-tooltip></el-table-column>-->
                                    <!--                                    <el-table-column prop="totalQuantity" label="总库存" align="center"-->
                                    <!--                                                     show-overflow-tooltip></el-table-column>-->
                                    <el-table-column prop="outCount" label="出库数量" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                </el-table>
                            </td>
                        </tr>

                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                :title="printTitle"
                :visible.sync="exportDialogVisible"
                width="80%"
                :close-on-click-modal="false"
                center
            >
                <div class="dialog-time">
                    <el-date-picker
                            v-model="startDate"
                            type="date"
                            size="small"
                            :clearable="false"
                            placeholder="选择开始时间"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    至
                    <el-date-picker
                            v-model="endDate"
                            type="date"
                            placeholder="选择结束时间"
                            size="small"
                            :clearable="false"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-button :loading="filterLoading" :disabled="filterLoading" type="primary" size="mini" style="margin-left:15px" @click="dialogFilter">筛 选</el-button
                >
                </div>
                <el-table
                    :data="excelTableData"
                    max-height="400px"
                    size="small"
                    row-class-name="row"
                    cell-class-name="column"
                    :highlight-current-row="true"
                    fit
                >
                    <el-table-column
                    v-for="(item, index) in jsonFields"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label"
                    show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
                <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                <download-excel
                        style="width: 80px;margin: 0px auto;margin-top: 10px;"
                        :data="json_data"
                        :fields="json_fields"
                        :name="printName"
                        >
                    <el-button
                        type="primary"
                        size="small"
                        @click="exportDialogVisible = false"
                        >导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
        <div class="edit-dialog">
            <el-dialog title="选择物料信息"
                       :close-on-click-modal="false"
                       :visible.sync="materialDialogVisible"
                       center>
                <div style="display: flex;margin-bottom: 10px">
                    <div style="width: 80px;margin-top: 5px">
                        物料编码：
                    </div>
                    <div>
                        <el-input v-model="searchBasicItem.materialCode" size="small"
                                  placeholder="请输入物料编码"
                        ></el-input>
                    </div>
                    <div style="width: 80px;margin-top: 5px">
                        选择部件：
                    </div>
                    <div>
                        <el-select v-model="searchBasicItem.component" style="width: 100%"
                                   size="small"
                                   filterable placeholder="请选择部件名称">
                            <el-option
                                    v-for="(item,index) in componentNameList"
                                    :key="index"
                                    :label="item.componentName"
                                    :value="item.component">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="text-align: center;width: 200px">
                        <el-button type="primary" icon="el-icon-search" size="small"
                                   @click="searchBasicBtn">搜索
                        </el-button>
                        <el-button type="primary" icon="el-icon-refresh" size="small"
                                   @click="resetBasicBtn">重置
                        </el-button>
                    </div>
                </div>
                <el-table
                        ref="multipleMaterialTable"
                        :data="tableAfterList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        max-height="400"
                        border
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: lineHeight}"
                        @selection-change="handleSelectionChangeAfter"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="componentName" label="部件" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="model" label="型号" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="outCount" label="数量" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.outCount" v-if="scope.row.checked == false"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      v-model.number="scope.row.outCount" v-else
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="materialDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitMaterial">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import dateFormat from '../../assets/js/dateFormat'
    export default {
        data() {
            return {
                materialDialogVisible: false,
                materialVisible: false,
                searchBasicItem: {
                    materialCode: '',
                    component: '',
                },
                searchMaterialItem: {
                    materialCode: '',
                    component: '',
                },
                warehouseTypeArr:[],
                table: [],
                btnIndex:-1,
                mainMaterialListStr:"",
                tableAfterListStr:"",
                mainMaterialList:[],
                tableAfterList:[],
                componentNameList:[],
                multipleSelection: [],
                filterLoading:false,
                excelTableData:[],
                startDate:'',
                endDate:'',
                jsonFields: [],
                json_fields: {},
                json_data: [],
                exportDialogVisible: false,
                printTitle:'出库单总表',
                printName:'出库单总表',
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                isDisabled: false,
                isLoad: false,
                lineHeight: '',
                editTitle: '',
                showTitle: '',
                searchItem: {
                    outNum: '',
                    warehouseType: [],
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                },
                depotTypes: [
                    {
                        value: 1,
                        label: '研发出库'
                    },
                    {
                        value: 2,
                        label: '外协出库'
                    },
                    {
                        value: 3,
                        label: '售后出库'
                    },
                    {
                        value: 4,
                        label: '生产领料'
                    },
                    {
                        value: 5,
                        label: '售后维修出库'
                    }
                ],
                depotList: [
                    {
                        value: 0,
                        label: '主物料仓库'
                    },
                    {
                        value: 1,
                        label: '研发物料仓库'
                    },
                    {
                        value: 2,
                        label: '外协仓库'
                    },
                    {
                        value: 3,
                        label: '售后仓库'
                    }
                ],
                outDepotForm: {
                    id: null,
                    outType: '',
                    warehouseType: '',
                    mainOutDetailStr: '',
                    remark: '',
                },
                outDisplay: {
                    outNum: '',
                    warehouseType: '',
                    outType: '',
                    remark: '',
                    isOuting: '',
                    outTime: '',
                    list: []
                },
                editSearchItem: {
                    materialCode: '',
                    component: ''
                },
                outDepotRules: {
                    outType: [{required: true, message: '请选择出库类型', trigger: 'blur'}],
                    warehouseType: [{required: true, message: '请选择出库仓库', trigger: 'blur'}]
                },
                tableList: [],
                new_tableList:[],
                materialList: [],
                type: 0,
                parts: [],
                multipleSelection0: [],
                purchaseList: [],
            };
        },
        methods: {
            //删除物料列表
            deleteNew_tableList(index){
                this.new_tableList = this.new_tableList.filter((item,i)=>index!=i);
            },
            //新增物料
            addAfterMaterial(type) {
                this.tableAfterList = []
                this.btnIndex = type;
                this.materialDialogVisible = true;
                var componentNames = []
                this.$api.getMaterialList({warehouseType: type}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            var componentObj = {};
                            componentObj.component = item.component;
                            componentObj.componentName = item.componentName;
                            componentNames.push(componentObj)
                            // if(this.materialTable){
                            //     if (JSON.stringify(this.materialTable).indexOf(item.id) != -1){
                            //         item.checked = true;
                            //     }else {
                            //         item.checked = false;
                            //     }
                            // }else {
                            //     item.checked = false;
                            // }
                        }
                        // var checkedArr = []
                        // for (const item of res.data) {
                        //     if (this.materialTable){
                        //         for (var itemObj of this.materialTable) {
                        //             if (item.id == itemObj.id){
                        //                 item.outCount = itemObj.outCount;
                        //             }
                        //         }
                        //     }
                        //     if (item.checked) {
                        //         checkedArr.push(item)
                        //     }
                        // }
                        // var newArr = []
                        // for (var item of checkedArr) {
                        //     newArr.push(item);
                        // }
                        // for (var item of res.data) {
                        //     newArr.push(item);
                        // }
                        // this.tableAfterList = this.uniqueId(newArr);
                        this.tableAfterList = res.data;
                        this.tableAfterListStr = JSON.stringify(this.uniqueId(res.data));
                        // this.tableAfterListStr = res.data;

                        this.componentNameList = this.uniqueCom(componentNames);
                        // this.toggleSelection(checkedArr)
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            //搜索物料
            searchBasicBtn() {
                var newArr = [];
                var checkList = []
                var materials = JSON.parse(this.tableAfterListStr)
                for (const item of this.mainMaterialList) {
                    if (item.checked){
                        checkList.push(item)
                    }
                }
                for (var item of materials) {
                    checkList.push(item)
                }
                this.tableAfterListStr = JSON.stringify(this.uniqueId(checkList));
                for (var item of this.uniqueId(checkList)) {
                    if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined
                        && this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                                newArr.push(item)
                            }
                        }
                    } else if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined) {
                        if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                            newArr.push(item)
                        }
                    } else if (this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            newArr.push(item)
                        }
                    }else {
                        newArr.push(item)
                    }
                }
                this.tableAfterList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(newArr)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            //重置物料
            resetBasicBtn() {
                var allArr = JSON.parse(this.tableAfterListStr);
                this.searchBasicItem = {
                    materialCode: '',
                    component: '',
                }
                var newArr = [];
                for (var item of this.tableAfterList) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (var item of allArr) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (const allArrElement of allArr) {
                    newArr.push(allArrElement)
                }
                this.tableAfterList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(this.tableAfterList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.multipleMaterialTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            //新增物料确定按钮
            submitMaterial() {
                var newArr = [];
                if (this.tableAfterList.length < 1) {
                    this.$message.error('所选新增的部件物料不能为空')
                    return;
                }
                for (var item of this.tableAfterList) {
                    if (item.checked) {
                        if (item.outCount < 1 || item.outCount == '') {
                            this.$message.error('所选新增的部件物料数量不能为空或者0')
                            return;
                        }
                        item.warehouseTypeStr = this.btnIndex;
                        newArr.push(item);
                    }
                }
                if(newArr.length < 1){
                    this.$message.error('请确认是否勾选物料')
                    return;
                }
                this.materialTable = newArr;
                //判断this.new_tableList有没有值
                if(this.new_tableList.length < 1){
                    this.new_tableList = newArr
                }else{
                    //去重
                    var tempList = [];
                    for(let i=0; i<newArr.length; i++){
                        var flag = false;
                        for(let j=0; j<this.new_tableList.length; j++){
                            if(newArr[i].id != this.new_tableList[j].id ){
                                flag = true;
                            }else{
                                if(newArr[i].warehouseTypeStr != this.new_tableList[j].warehouseTypeStr){
                                    flag = true;
                                }else{
                                    flag = false;
                                    break;
                                }
                            }
                        }
                        if(flag){
                            newArr[i].warehouseTypeStr = this.btnIndex;
                            tempList.push(newArr[i])
                        }
                    }
                    this.new_tableList = this.new_tableList.concat(tempList)
                }
                this.materialDialogVisible = false;
            },
            //售后物料表格勾选
            handleSelectionChangeAfter(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableAfterList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableAfterList) {
                        item.checked = false;
                    }
                    for (var item of this.tableAfterList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            uniqueCom(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            toggleSelectionMaterial(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.mainMaterialTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            //导出excel
            exportExcel(index) {
                if(index === 1){
                    this.printTitle = '出库单总表';
                    this.printName = '出库单总表';
                    this.jsonFields = [
                        { label: "合同号", prop: "contractNum" },
                        { label: "合同名称", prop: "contractName" },
                        { label: "客户名称", prop: "customerName" },
                        { label: "物料编码", prop: "materialCode" },
                        { label: "产品名称", prop: "materialName" },
                        { label: "型号", prop: "model" },
                        { label: "单位", prop: "unitName" },
                        { label: "出库日期", prop: "createTime" },
                        { label: "出库类型", prop: "outType" },
                        { label: "数量", prop: "outCount" },
                        { label: "单价(元)", prop: "buyPrice" },
                        { label: "金额合计(元)", prop: "buySum" },
                    ]
                }else{
                    this.printTitle = '售后出库总表';
                    this.printName = '售后出库总表';
                    this.jsonFields = [
                        { label: "出库单编号", prop: "outNum" },
                        { label: "出库类型", prop: "outType" },
                        { label: "出库仓库", prop: "warehouseType" },
                        { label: "物料名称", prop: "materialName" },
                        { label: "客户名称", prop: "customerName" },
                        { label: "项目名称", prop: "projectName" },
                        { label: "型号", prop: "model" },
                        { label: "单位", prop: "unitName" },
                        { label: "数量", prop: "outCount" },
                        { label: "单价(元)", prop: "buyPrice" },
                        { label: "金额合计(元)", prop: "buySum" },
                        { label: "操作员", prop: "updateUserName" },
                    ]
                }
                this.jsonFields.map((item, i) => {
                    this.json_fields[item.label] = item.prop;
                });
                this.exportDialogVisible = true;
                var day1 = new Date();
                day1.setDate(day1.getDate() + 1);
                this.startDate = dateFormat("YYYY-mm-DD", new Date())
                this.endDate = dateFormat("YYYY-mm-dd ", day1)
                this.dialogFilter()
            },
            //导出excel的筛选
            dialogFilter(){
                this.filterLoading = true
                this.$api.outStorageDetailPrint({startDate:this.startDate,endDate:this.endDate}).then(res=>{
                    this.filterLoading = false
                    if(res.code == 200){
                        res.data.forEach(item => {
                            item.buyPrice = item.outCount == 0 ? 0 : (item.buySum/item.outCount/100).toFixed(2)
                            item.buySum = (item.buySum/100).toFixed(2)
                            switch(item.outType){
                                case 0:
                                    item.outType = '销售出库';
                                    break;
                                case 1:
                                    item.outType = '研发出库';
                                    break;
                                case 2:
                                    item.outType = '外协物料出库';
                                    break;
                                case 3:
                                    item.outType = '售后出库';
                                    break;
                                case 4:
                                    item.outType = '生产领料';
                                    break;
                                case 5:
                                    item.outType = '售后维修出库';
                                    break;
                                case 10:
                                    item.outType = '虚拟出库(物料耗损)';
                                    break;
                                default:
                                    item.outType = '未找到该类型';
                            }
                            switch(item.warehouseType){
                                case 0:
                                    item.warehouseType = '主物料仓库';
                                    break;
                                case 1:
                                    item.warehouseType = '研发物料仓库';
                                    break;
                                case 2:
                                    item.warehouseType = '外协仓库';
                                    break;
                                case 3:
                                    item.warehouseType = '售后仓库';
                                    break;
                                default:
                                    item.warehouseType = '未找到该类型';
                            }
                        });
                        this.excelTableData = res.data
                        this.json_data = res.data
                    }
                })
            },
            // 格式化出库仓库字段
            warehouseType_Formart(arr){
                arr.map(item=>{
                    var str = "";
                    var strArr = "";
                    if(item.warehouseType != undefined){
                        strArr = item.warehouseType.split(",")
                    }else{
                        strArr = item.warehouseTypeStr.split(",")
                    }
                    strArr.map(item2=>{
                        var type = "";
                        if(item2 == 0){
                            type = "主物料仓库"
                        }else if(item2 == 1){
                            type = "研发物料仓库"
                        }
                        else if(item2 == 2){
                            type = "外协物料仓库"
                        }
                        else if(item2 == 3){
                            type = "售后物料仓库"
                        }
                        str += type + "，"
                    })
                    str = str.slice(0,str.length-1);
                    item.warehouseType = str
                })
                return arr;
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageOutWarehouse(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.warehouseType_Formart(res.data.content)
                        this.tableData = res.data.content;
                        this.totalPage = res.data.numberOfElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageOutWarehouse(this.searchItem).then(res => {
                    if (res.code == 200) {
                        res.data.content.map(item=>{
                            var str = "";
                            var strArr = item.warehouseType.split(",")
                            strArr.map(item2=>{
                                var type = "";
                                if(item2 == 0){
                                    type = "主物料仓库"
                                }else if(item2 == 1){
                                    type = "研发物料仓库"
                                }
                                else if(item2 == 2){
                                    type = "外协物料仓库"
                                }
                                else if(item2 == 3){
                                    type = "售后物料仓库"
                                }
                                str += type + "，"
                            })
                            str = str.slice(0,str.length-1);
                            item.warehouseType = str
                        })
                        this.tableData = res.data.content;
                        this.totalPage = res.data.numberOfElements;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    outNum: '',
                    warehouseType: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addObject() {
                this.new_tableList=[];
                this.editDialogVisible = true;
                this.isDisabled = false;
                this.tableList = []
                this.type = 0;
                this.editTitle = '新增出库信息'
                this.outDepotForm = {
                    id: null,
                    outType: null,
                    warehouseType: null,
                    purchaseOrder: '',
                    remark: '',
                    list: []
                }
                this.editSearchItem = {
                    omaterialCode: '',
                    component: ''
                }
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                var materialStr = '';
                for (var item of this.new_tableList) {
                    if (item.quantity < item.outCount) {
                        this.$message.error('出库数量不能大于库房库存');
                        this.isLoad = false;
                        return;
                    } else if (item.outCount == 0 || item.outCount == '' || item.outCount == undefined || item.outCount == null) {
                        this.$message.error('所选的物料出库的数量不能为0或者为空');
                        this.isLoad = false;
                        return;
                    }
                    if (materialStr == '') {
                        materialStr = item.id + ',' + item.outCount + ',' + item.warehouseTypeStr.toString();
                    } else {
                        materialStr = materialStr + '|' + item.id + ',' + item.outCount + ',' + item.warehouseTypeStr.toString();
                    }
                }
                this.outDepotForm.mainOutDetailStr = materialStr;
                if((typeof this.outDepotForm.warehouseType) != 'object'){
                    this.outDepotForm.warehouseType = [];
                }
                if(!this.outDepotForm.warehouseType){
                    this.outDepotForm.warehouseType = [];
                }
                let tempStr = "";
                this.outDepotForm.warehouseType.map(item=>{
                    tempStr += item+",";
                })
                // this.outDepotForm.warehouseType = 0
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.outDepotForm.warehouseType = tempStr.slice(0,tempStr.length-1);
                        if (this.type == 0) {
                            this.$api.addOutWarehouse(this.outDepotForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.$message.success(res.message)
                                    this.editDialogVisible = false
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message)
                                    this.isLoad = false;
                                }
                            })
                        } else {
                            this.$api.updateOutWarehouse(this.outDepotForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.$message.success(res.message)
                                    this.editDialogVisible = false
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message)
                                    this.isLoad = false;
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            },
            //编辑
            editOutDepot(id) {
                this.type = 1;
                this.$api.getOutWarehouseDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editTitle = '编辑《' + res.data.outNum + '》出库信息';
                        var typeArr = res.data.warehouseType.split(",")
                        var type = typeArr.map(item=> Number(item) )
                        this.outDepotForm = {
                            id: res.data.id,
                            outType: res.data.outType,
                            warehouseType: type,
                        }
                        for (var item of res.data.materialList) {
                            item.checked = true;
                            item.totalQuantity = item.quantity + item.tempQuantity
                        }
                        this.tableList = res.data.materialList;
                        this.new_tableList = res.data.materialList;
                        // this.getEditMaterialList(res.data.warehouseType);
                        this.editDialogVisible = true;
                    }
                })
            },
            submitOutDepot(id) {
                this.$confirm('你确定要出库该出库单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.sureOutWarehouse({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            showOutDepot(id) {
                this.infoDialogVisible = true;
                this.$api.getOutWarehouseDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.showTitle = '《' + res.data.outNum + '》出库信息详情'
                        var str = "";
                        var strArr = res.data.warehouseType.split(",")
                        strArr.map(item2=>{
                            var type = "";
                            if(item2 == 0){
                                type = "主物料仓库"
                            }else if(item2 == 1){
                                type = "研发物料仓库"
                            }
                            else if(item2 == 2){
                                type = "外协物料仓库"
                            }
                            else if(item2 == 3){
                                type = "售后物料仓库"
                            }
                            str += type + "，"
                        })
                        str = str.slice(0,str.length-1);

                        this.outDisplay = {
                            outType: res.data.outType,
                            isOuting: res.data.isOuting,
                            outNum: res.data.outNum,
                            warehouseType: str,
                            remark: res.data.remark,
                            outTime: res.data.outTime,
                            //这里不用转换也可以，他只有一个类型
                            list: this.warehouseType_Formart(res.data.materialList) 
                        }
                    }
                })
            },
            unique(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
        },
        created() {
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .icontongguo {
        color: green;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .edit-select {
        margin-bottom: 10px;
    }
</style>